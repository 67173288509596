<mat-spinner
  class="spinner"
  [diameter]="50"
  *ngIf="spinnerService.visibility | async"
></mat-spinner>
<mat-toolbar *ngIf="!hideNotification" class="notification">
  <p>{{ data.getNotification.message }}</p>
</mat-toolbar>
<mat-toolbar color="secondary" *ngIf="!hideNavbar">
  <div id="main_logo">
    <a [routerLink]="['/']">
      <svg viewBox="0 0 180 60">
        <use xlink:href="/assets/img/app_assets.svg#logo"></use>
      </svg>
    </a>
  </div>

  <span class="flex-spacer"></span> <!-- Replaces <span fxFlex></span> -->
  <div
    *ngIf="isCMS && isLogin && !isProjectWizard && !isCampaignsWizard && !isAccountPage"
    class="nav-container"
  >
    <div class="page-nav" *ngFor="let item of navSections | keyvalue">
      <a
        mat-button
        href="javascript:void(0)"
        (click)="scrollToSection(item.key)"
        >{{ item.value }}</a
      >
    </div>
  </div>
  <div
    *ngIf="isCMS && isLogin && excludeCustomNav && !isTrial"
    class="nav-container"
  >
    <div class="page-nav">
      <a
        mat-button
        href="#"
        routerLink="/cms/dashboard"
        routerLinkActive="active"
        >Dashboard</a
      >
    </div>
  </div>

  <div
    *ngIf="!isCMS && isLogin && isEligible"
    class="nav-container"
  >
    <div class="page-nav" *ngFor="let item of navSections | keyvalue">
      <a
        mat-button
        href="javascript:void(0)"
        (click)="scrollToSection(item.key)"
        >{{ item.value }}</a
      >
    </div>
  </div>

  <div class="toolbar-end">
    <a mat-button routerLink="/about" *ngIf="!isLogin">For Business</a>
    <a mat-button *ngIf="!isLogin" (click)="openLoginDialog()">Login</a>

    <button
      *ngIf="isLogin"
      mat-button
      [matMenuTriggerFor]="menu"
      class="user-dropdown toolbar-end"
    >
      {{ currentUser ? currentUser.email : "" }}
    </button>
    <mat-menu #menu="matMenu">
      <div *ngIf="currentUser" class="user-id">
        Account ID: {{ currentUser ? currentUser._id : "" }}
      </div>
      <div
        mat-menu-item
        *ngIf="currentUser && currentRole === 'customer'"
        (click)="$event.stopPropagation()"
      >
        <mat-slide-toggle
          color="primary"
          [(ngModel)]="videoTour"
          (change)="onTour()"
          >Video Tour</mat-slide-toggle
        >
      </div>
      <div *ngIf="currentUser">
        <a
          *ngIf="currentRole === 'customer'"
          mat-menu-item
          href="#"
          routerLink="/dashboard"
          routerLinkActive="active"
          >Account Dashboard</a
        >
        <a
          *ngIf="currentRole === 'Business'"
          mat-menu-item
          href="#"
          routerLink="/cms/account"
          routerLinkActive="active"
          >Account Dashboard</a
        >
      </div>
      <button mat-menu-item *ngIf="isLogin" (click)="openLogout()">
        Logout
      </button>
    </mat-menu>
    <a mat-button *ngIf="!isLogin" (click)="openSignup()">Sign Up</a>
  </div>

  <div
    *ngIf="isLogin && isEligible && !isTrial"
    class="hamburger-menu-container"
  >
    <div class="hamburger-menu" (click)="sidenav.toggle()">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</mat-toolbar>

<mat-sidenav-container
  style="overflow: visible; flex-grow: 1"
  class="mobile-container sidenav-container"
>
  <mat-sidenav #sidenav class="sidenav-column"> <!-- Added class for flex layout column -->
    <div class="sidenav-content"> <!-- Added class for flex layout column -->
      <!-- Sidenav content remains the same -->
    </div>
  </mat-sidenav>

  <mat-sidenav-content style="overflow: visible" class="sidenav-content-fill">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
