<div class="modal-content">
  <!-- Title Section -->
  <h2 class="modal-title">
    Unlock Your Potential with <span class="digitpop-brand">DigitPop!</span>
  </h2>

  <!-- Credits Highlight -->
  <p class="credits-highlight">
    Earn up to <span class="credits-value">100 credits</span> by signing up.
  </p>

  <div class="dpop-cont-video">
    <video poster="../../assets/video/video_10.jpg" controls class="noautoplay">
      <source src="../../assets/video/video_10.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>

  <!-- Brief Description -->
  <p class="modal-description">
    Unlock premium content with shoppable videos. Redeem tokens for services
    like Netflix, Disney+, or cash via PayPal.
  </p>

  <!-- Email Input Form -->
  <form class="waiting-list-form" (ngSubmit)="addToWaitingList()">
    <input
      type="email"
      [(ngModel)]="email"
      name="email"
      class="email-input"
      placeholder="Enter your email"
      required
    />
    <button type="submit" class="join-waiting-list-btn">
      Join Waiting List
    </button>
    <div
      *ngIf="message"
      class="message-box"
      [ngClass]="{ 'error-message': isError, 'success-message': !isError }"
    >
      {{ message }}
    </div>
  </form>

  <!-- Close Button -->
  <button class="close-btn" (click)="closeModal()">&times;</button>


</div>
