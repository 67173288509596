import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WaitingListEntry } from '../models/waitingListEntry';
import { environment } from 'projects/DigitPop-CMS/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WaitingListService {
  readonly endpoint = `${environment.apiUrl}/api/waitinglist`;

  constructor(private http: HttpClient) { }

  // Add a new email to the waiting list
  addToWaitingList(email: string): Observable<WaitingListEntry> {
    return this.http.post<WaitingListEntry>(this.endpoint, { email });
  }

  // Get the waiting list (or a part of it)
  getWaitingList(): Observable<WaitingListEntry[]> {
    return this.http.get<WaitingListEntry[]>(this.endpoint);
  }

  // Other methods related to the waiting list can go here
}
