<div class="retry-quiz-container">
  <div class="retry-quiz-header">Oops! That's not quite right.</div>
  <div class="retry-quiz-instructions">
      You can always learn more and try the quiz again. Would you like to retry?
  </div>
  <div class="retry-quiz-buttons">
      <button type="button" class="retry-quiz-button btn-secondary" (click)="close(false)">No, Thanks</button>
      <button type="button" class="retry-quiz-button btn-primary" (click)="close(true)">Yes, Retry</button>
  </div>
</div>

