'use strict';
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { VideosGridService } from '../shared/services/videos-grid.service';
import { EngagementService } from '../shared/services/engagement.service';
import { XchaneAuthenticationService } from '../shared/services/xchane-auth-service.service';
import { ProjectMedia } from '../shared/models/ProjectMedia';
import { Category } from '../shared/models/category';
import { AnswerDialogComponent } from '../xchane/answer-dialog/answer-dialog.component';
import { PlayerComponent } from '../xchane/player/player.component';
import { Observable, Subscription, timer } from 'rxjs';
import { VisitorPopupComponent } from '../visitor-popup/visitor-popup.component';
import { XchaneUser } from '../shared/models/xchane.user';
import { DataService } from '../xchane/services/data.service';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { debounceTime } from 'rxjs/operators';

interface VideoResponse {
  videos: ProjectMedia[];
  nextCreatedAt: string | null;
}

@Component({
  selector: 'digit-pop-videos-grid',
  templateUrl: './videos-grid.component.html',
  styleUrls: ['./videos-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideosGridComponent implements OnInit, AfterViewInit {
  @ViewChild(CdkScrollable) scrollable: CdkScrollable;

  currentVideoIndex: number = 0;
  selectedCategories: string[] = [];
  categories: string[] = [];
  activeCategories: Category[] = [];
  categoryVideosCount: number;
  videos: ProjectMedia[] = [];
  videosLoaded = true;
  videosLoading = false;
  videosLimit = 20;
  videosCount: number[];
  page = 0;
  projectId: string;
  campaignId: string;
  categoryId: string;
  popupDialogRef: MatDialogRef<PlayerComponent>;
  previewDialogRef: MatDialogRef<VideoPlayerComponent>;
  monthNames: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  videoTour = true;
  isUser: string | boolean;
  popupOpened = false;
  dialogOpen = false;
  loggedIn = false;
  lastCampaignId: string | null;
  private scrollSubscription: Subscription;

  // tslint:disable-next-line:max-line-length
  constructor(
    private scrollDispatcher: ScrollDispatcher,
    private videosService: VideosGridService,
    private engagementService: EngagementService,
    private authService: XchaneAuthenticationService,
    private dialog: MatDialog,
    private router: Router,
    private data: DataService
  ) {
    this.lastCampaignId = null;
    this.videosCount = Array(this.videosLimit)
      .fill(0)
      .map((x, i) => i);
    this.categoryVideosCount = 0;
    this.selectedCategories = ['Clothing'];

    if (
      sessionStorage.getItem('XchaneCurrentUser') ||
      localStorage.getItem('XchaneCurrentUser')
    ) {
      this.loggedIn = true;
    }

    this.data.getLogin().subscribe((state) => {
      this.loggedIn = state.loggedIn;
    });

    this.data.getVideTour().subscribe((state) => {
      this.videoTour = state.enabled;
    });
  }

  ngOnInit(): void {
    if (this.authService.currentUserValue) {
      this.videoTour =
        'tour' in this.authService.currentUserValue
          ? this.authService.currentUserValue.tour
          : true;
    }

    this.getCategories();
  }

  ngOnDestroy() {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  loadMoreVideos() {
    // Guard against loading if already fetching videos
    console.log('this.videosLoading: ' + this.videosLoading);

    if (!this.videosLoading) {
      this.getVideos(true);
    }
  }

  ngAfterViewInit() {
    // Now you can use 'this.scrollable' to access the CdkScrollable instance
    this.scrollSubscription = this.scrollDispatcher
      .scrolled()
      .pipe(debounceTime(500))
      .subscribe(() => {
        console.log('scroll event wzzzaaup');

        if (!this.scrollable) {
          console.error('CdkScrollable is null or undefined');
          return;
        }

        const elementRef = this.scrollable.getElementRef();
        const element = elementRef.nativeElement;

        // Check if 'element' is null or undefined
        if (!element) {
          console.error('Native element is null or undefined');
          return;
        }

        console.log('element:', element);

        const viewHeight = element.clientHeight; // viewport height
        console.log('viewHeight:', viewHeight);

        const contentHeight = element.scrollHeight; // length of entire content
        console.log('contentHeight:', contentHeight);

        const scrollEnd = element.scrollTop; // current scroll position from the top
        console.log('scrollEnd:', scrollEnd);

        const distanceFromBottom = contentHeight - viewHeight - scrollEnd;
        console.log('distanceFromBottom:', distanceFromBottom);

        if (distanceFromBottom < 300) {
          console.log('Near bottom, attempting to load more videos');
          this.loadMoreVideos();
        } else {
          console.log('Not near bottom, no load triggered');
        }
      });
  }

  buildGrid: () => void = async () => {
    this.videosLoaded = true;
    this.videosLoading = false;
  };

  getCategories: () => void = () => {
    return this.videosService
      .getActiveCategories()
      .subscribe((response: Category[]) => {
        this.activeCategories = [
          {
            _id: '',
            name: 'All',
            description: '',
          },
          ...this.sortCategories(response),
        ];
        // this.categories = this.selectedCategories = this.activeCategories.map(category => category.name);
        this.categories = this.activeCategories.map(
          (category) => category.name
        );
        this.getVideos();
      });
  };

  sortCategories = (response: Category[]) => {
    const startingCategories = ['Clothing', 'Cosmetics'];
    const filteredResponse: Category[] = [];

    const preferredSort = response.filter((category) => {
      if (startingCategories.includes(category.name)) {
        response.pop();
        return true;
      }

      filteredResponse.push(category);
      return false;
    });

    return [...preferredSort, ...filteredResponse];
  };

  // setCategory: (category: string) => void = (category: string) => {
  //   this.lastCampaignId = null;
  //   this.selectedCategories = category === 'All' ? this.categories : [category];
  //   this.page = 0;
  //   this.getVideos();
  // };

  // Add a new property to keep track of the createdAt timestamp of the last loaded video
  lastVideoCreatedAt: Date | null = null;

  getVideos(isAppend: boolean = false): void {
    if (this.videosLoading) {
      console.log('Videos are already loading.');
      return;
    }

    this.videosLoading = true;
    console.log(`Fetching videos for page: ${this.page}`);

    const currentUserId = this.authService.currentUserValue
      ? this.authService.currentUserValue._id
      : null;

    // Prepare the parameters for the API call, including the lastVideoCreatedAt if available
    const params = {
      categories: this.selectedCategories.join(', '),
      page: this.page,
      limit: this.videosLimit,
      userId: currentUserId,
      // Replace lastCampaignId with lastVideoCreatedAt
      lastCreatedAt: this.lastVideoCreatedAt
        ? this.lastVideoCreatedAt.toISOString()
        : null,
    };

    // Log the parameters including the new lastVideoCreatedAt
    console.log('Calling getVideos with parameters:', params);

    // Inside your component where you're calling getVideos
    this.videosService
      .getVideos(
        this.selectedCategories,
        this.videosLimit,
        currentUserId,
        this.lastVideoCreatedAt ? this.lastVideoCreatedAt.toISOString() : null
      )
      .subscribe(
        (response: VideoResponse) => {
          // Use the VideoResponse interface here
          const { videos, nextCreatedAt } = response; // Destructure the response

          console.log('Videos:', videos);

          // Check if the videos array is not empty
          if (videos && videos.length > 0) {
            // Update the lastVideoCreatedAt with the createdAt of the last video fetched
            this.lastVideoCreatedAt = new Date(
              videos[videos.length - 1].createdAt
            );
            console.log(
              `Updated lastVideoCreatedAt: ${this.lastVideoCreatedAt.toISOString()}`
            );

            // Append the new videos to the existing list
            this.videos = [...this.videos, ...videos];

            // If you need to use nextCreatedAt for the next request, save it as needed
            // this.nextCreatedAt = nextCreatedAt;

            // Other code for updating UI or state after fetching new videos
          } else {
            console.log('No more videos to fetch.');
            // Handle the case where there are no more videos
          }
          this.videosLoading = false;
        },
        (error: Error) => {
          console.error('Error fetching videos:', error);
          this.videosLoading = false;
        }
      );
  }

  previewVideo = (event: Event) => {
    const thumbnail = event.target as Element;
    const video = thumbnail.querySelector('video') as HTMLVideoElement;

    video.muted = true;
    video.loop = true;
    video.currentTime = 1;

    video.play().then(() => {
      thumbnail.addEventListener('mouseleave', () =>
        this.stopPreview(thumbnail, video)
      );
    });
  };

  stopPreview = (thumbnail: Element, video: HTMLVideoElement) => {
    thumbnail.removeEventListener('mouseleave', () => this);
    video.load();
  };

  openPlayer = async (
    id: string,
    campaignId: string,
    categoryId: string,
    event: Event | null = null,
    index?: number // Add the index parameter
  ) => {
    if (event) {
      event.preventDefault();
    }

    // Update the current video index
    this.currentVideoIndex = index;

    this.projectId = id;
    this.campaignId = campaignId;
    this.categoryId = categoryId;

    console.log('Project Id: ' + this.projectId);
    console.log('Campaign Id: ' + this.campaignId);
    console.log('Category Id: ' + this.categoryId);

    const dialogConfig = new MatDialogConfig();

    const getUserId = (): string | boolean => {
      const sessionUser = sessionStorage.getItem('XchaneCurrentUser');
      const localUser = localStorage.getItem('XchaneCurrentUser');
      return sessionUser
        ? JSON.parse(sessionUser)._id
        : localUser
        ? JSON.parse(localUser)._id
        : false;
    };

    const isUser = !!getUserId();
    const userId = getUserId(); // Simplified this call

    this.isUser = isUser;

    dialogConfig.data = {
      id,
      campaignId,
      userId,
      categoryId,
      videoTour: this.videoTour,
    };

    dialogConfig.panelClass = 'video-player-dialog';
    this.previewDialogRef = this.dialog.open(
      VideoPlayerComponent,
      dialogConfig
    );

    this.previewDialogRef.afterClosed().subscribe((data) => {
      if (data !== undefined) {
        this.handlePostQuiz(data);
      }
    });
  };

  // loadMoreVideos = () => {
  //   this.videosCount = Array(this.categoryVideosCount - ((this.page + 1) * this.videosLimit)).fill(0).map((x, i) => i);
  //   this.MoreVideosLoaded = false;
  //   this.page++;
  //   this.getVideos(true);
  // }

  prettyDate = (d: Date) => {
    const date = new Date(d);
    return `${
      this.monthNames[date.getMonth()]
    }, ${date.getDate()} - ${date.getFullYear()}`;
  };

  handlePostQuiz = (isCorrect: any) => {
    let confirmDialog: any;

    if (this.isUser) {
      isCorrect = isCorrect.correct;
    }

    if (!isCorrect && !this.dialogOpen) {
      confirmDialog = this.dialog.open(AnswerDialogComponent, {
        data: {
          title: 'Incorrect Answer',
          message: 'Incorrect Answer, would you like to try again?',
        },
      });

      this.dialogOpen = true;

      return confirmDialog.afterClosed().subscribe((result: boolean) => {
        this.dialogOpen = false;
        confirmDialog.close();

        if (result === true) {
          this.openPlayer(this.projectId, this.campaignId, this.categoryId);
        }
      });
    }

    this.videos = this.videos.map((video) => {
      if (video._id !== this.projectId) {
        return video;
      }

      video.watched = true;
      return video;
    });
  };

  openVisitorPopup = () => {
    if (this.popupOpened) {
      return;
    }
    this.popupOpened = true;
    const dialogRef = this.dialog.open(VisitorPopupComponent, {
      maxWidth: '90%',
      data: {
        source: 'quiz',
        campaignId: this.campaignId,
        projectId: this.projectId,
      },
      panelClass: 'dpop-modal',
    });

    dialogRef.afterClosed().subscribe(() => {});
  };

  private refreshUser = () => {
    this.authService.getCurrentXchaneUser().subscribe(
      (user) => {
        let use = new XchaneUser();
        use = user as XchaneUser;
        this.authService.storeUser(use);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatDuration' })
export class FormatDurationPipe implements PipeTransform {
  transform(value: number): string {
    // Debugging: Log the original value
    console.log('Original value:', value);

    // Convert value to a number in case it's being passed as a string
    const numValue = Number(value);

    // Debugging: Log the converted number value
    console.log('Converted number value:', numValue);

    // Check if the value is a valid number
    if (isNaN(numValue)) {
      console.error('Error: Value is NaN');
      return 'Invalid duration'; // Or return some default value or message
    }

    const minutes = Math.floor(numValue / 60);
    const seconds = Math.floor(numValue % 60);

    // Debugging: Log the calculated minutes and seconds
    console.log('Minutes:', minutes, 'Seconds:', seconds);

    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }
}
