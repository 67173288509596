<div id="top"></div>

<div id="dp-for-business" class="dpop-sect dpop-sect-pink dpop-sect-top">
  <div class="dpop-cont">
    <div class="dpop-title dpop-title-l">
      Engage Green, Advertise Smart – DigitPop's Sustainable Advertising
      Solution
    </div>
  </div>
  <div class="dpop-cont dpop-cont-border">
    <div class="dpop-sustainable-ad-text">
      <p>
        In the digital realm, advertising carries more than just promotional
        messages—it carries an environmental cost. With the industry accountable
        for an estimated 3.5% of global greenhouse emissions annually, the need
        for change is imperative. DigitPop stands at the forefront of this
        transformation, offering a greener approach to digital advertising by
        enabling user-driven ad engagement. This not only enhances ad relevance
        but significantly reduces emissions, paving the way for a more
        sustainable future in the digital space.
      </p>
    </div>
  </div>
  <div class="dpop-cont">
    <div class="dpop-cont-text">
      <div class="dpop-title">Publishers</div>
      <div>
        Increase your audience with a
        <span class="dpop-strong">new revenue stream</span>. Monetize with ad
        supported content that never interrupts your viewer.
      </div>
      <a href="#publishers" class="dpop-cta dpop-cta-s scrollanim-bounceIn-1000"
        >Learn more</a
      >
    </div>
    <div class="dpop-cont-text">
      <div class="dpop-title">Advertisers</div>
      <div>
        Increase your Return on Ad Spend (ROAS) with
        <span class="dpop-strong">Cost Per Completed Comprehension</span>
        pricing. Stop paying for views and impressions when no one is paying
        attention to your ad.
      </div>
      <a routerLink="/signup" class="dpop-cta dpop-cta-s scrollanim-bounceIn-1000">Try Free Trial</a>
    </div>
    <div class="dpop-cont-text">
      <div class="dpop-title">Viewers</div>
      <div>
        Tired of being interrupted by ads but don't want to pay? Enjoy your
        favorite content and earn rewards by watching and shopping on your own
        time
      </div>
      <a href="#consumers" class="dpop-cta dpop-cta-s scrollanim-bounceIn-1000"
        >Learn more</a
      >
    </div>
  </div>
</div>
<div class="dpop-sect dpop-sect-gray" id="advertisers">
  <div class="dpop-cont">
    <div class="dpop-cont-video">
      <div class="dpop-title">Why Advertise through DigitPop?</div>
      <video
        poster="../../assets/video/video_09.jpg"
        controls
        class="noautoplay"
      >
        <source src="../../assets/video/video_09.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="dpop-cont-video">
      <div class="dpop-title">What is Cost Per Completed Comprehension?</div>
      <video
        poster="../../assets/video/video_11.jpg"
        controls
        class="noautoplay"
      >
        <source src="../../assets/video/video_11.mp4" type="video/mp4" />
      </video>
    </div>
  </div>
</div>

<div class="dpop-sect dpop-sect-gray">
  <div class="dpop-cont">
    <div class="dpop-cont-video">
      <div class="dpop-title">
        Why host your videos on DigitPop instead of YouTube?
      </div>
      <video
        poster="../../assets/video/video_08.jpg"
        controls
        class="noautoplay"
      >
        <source src="../../assets/video/video_08.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="dpop-cont-video">
      <div class="dpop-title">DigitPop For Advertisers</div>
      <video
        poster="../../assets/video/video_07.jpg"
        controls
        class="noautoplay"
      >
        <source src="../../assets/video/video_07.mp4" type="video/mp4" />
      </video>
    </div>
  </div>
</div>

<div class="dpop-sect dpop-sect-white">
  <div class="dpop-cont dpop-cont-border">
    <div class="dpop-cont-video">
      <video poster="../../assets/video/video_04.jpg" loop>
        <source src="../../assets/video/video_04.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="dpop-cont-text">
      <div class="dpop-title">Analytics and Project Wizard</div>
      <div>
        The DigitPop toolkit for advertisers is where you can create, host and
        stream your shoppable videos across the internet. The Dashboard, in the
        toolkit, displays Shoppable Video projects and campaigns. Detailed
        analytics provide information about the products that are of interest to
        your customers.
      </div>
    </div>
  </div>
</div>

<div class="dpop-sect dpop-sect-white">
  <div class="dpop-cont dpop-cont-border">
    <div class="dpop-cont-text">
      <div class="dpop-title">Campaigns to reach new Audiences</div>
      <div>
        DigitPop is different because you only pay when the viewer proves they
        understand your message. With Cost Per Completed Comprehension pricing
        you won't be paying when the viewer idsn't paying attention like in Cost
        Per View pricing (CPV).
      </div>
    </div>
    <div class="dpop-cont-video">
      <video poster="../../assets/video/video_05.jpg" loop>
        <source src="../../assets/video/video_05.mp4" type="video/mp4" />
      </video>
    </div>
  </div>
</div>

<div class="dpop-sect dpop-sect-white">
  <div class="dpop-cont">
    <div class="dpop-cont-video">
      <video poster="../../assets/video/video_06.jpg" loop>
        <source src="../../assets/video/video_06.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="dpop-cont-text">
      <div class="dpop-title">
        The ad is gamified and the user proves the viewer understands your
        message
      </div>
      <div>
        The viewer completes a quiz at the end of ad and proves they understand
        the message you want to convey.
      </div>
    </div>
  </div>
</div>

<!-- Global Sign Up Call to Action -->
<div class="dpop-sect dpop-sect-footer">
  <div class="dpop-cont-footer-cta">
    <!-- Footer Call to Action -->
    <a routerLink="/signup" class="dpop-cta">Sign Up and Go Green</a>
  </div>
</div>
