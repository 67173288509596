<section [class]="closeClass" class="welcome">
  <div class="welcome--collapsed--message">
    <h1>Welcome to DigitPop!</h1>
    <button (click)="openSection()" class="welcome--button welcome--open">&#x26BA;</button>
  </div>
  <div class="container">
    <button (click)="collapseSection()" class="welcome--button welcome--collapse">&#x26BB;</button>
    <div class="welcome--video">
      <img (click)="toggleSound()" [src]='"assets/img/"+soundIcon+"_icon.svg"'
           class="welcome--video--sound"/>
      <video
        #welcomeVideo
        [src]="videoUrl"
        autoplay
        playsinline
        muted
        loop
        preload="auto"
        type="video/mp4"
      >
        Your browser does not support the video tag.
      </video>
    </div>
    <div class="welcome--content">
      <h1 class="welcome--content--heading">
        <span>Welcome</span><br> To <p>DigitPop</p>!
      </h1>
      <h2 class="welcome--content--description">Earn electronic gift cards just
        by watching videos.</h2>
      <div class="welcome--content--actions" fxLayout fxLayoutAlign="center">
        <button #replayButton (click)="replayVideo()" color="secondary"
                mat-flat-button>
          Replay Video
        </button>
        <button (click)="scrollToVideo()" color="primary" mat-flat-button>
          Start Earning
        </button>
      </div>
    </div>
  </div>
</section>
 <div #backdropBlur *ngIf="!closeSection" class="blurred-backdrop" (mouseenter)="disableBlur()"></div>
<div #welcomeAnchor></div>
