<section class="intro-popup">
  <div class="dpop-sect dpop-sect-pink">
    <div class="dpop-cont">
      <!-- Video container with selector buttons -->
      <div class="dpop-cont-video">
        <div class="video-selector">
          <button
            class="video-btn consumer-btn"
            (click)="changeVideo('consumer')"
          >
            For Consumers
          </button>
          <button
            class="video-btn publisher-btn"
            (click)="changeVideo('publisher')"
          >
            For Publishers
          </button>
        </div>
        <video [poster]="currentVideo.poster" controls class="noautoplay">
          <source [src]="currentVideo.src" type="video/mp4" />
        </video>
      </div>
      <!-- Text container -->
      <div class="dpop-cont-text">
        <h1 class="dpop-title">
          Explore DigitPop: A New Era in Digital Media Access and Monetization
        </h1>
        <p>
          Imagine accessing and monetizing digital content as seamlessly as
          logging into apps with social media accounts. DigitPop redefines how
          consumers engage with and pay for digital media, while providing
          publishers an innovative monetization method. Our platform introduces
          a unique third lane of monetization, linking consumer interactions
          directly with tangible rewards for publishers. With advanced tools,
          publishers can create a virtual lock-and-key system for digital
          assets, from subscriptions to exclusive in-game items. Consumers use
          blockchain-based tokens to unlock these assets, which, when redeemed,
          convert into direct USD payments to publishers. This model enhances
          publisher revenue and deepens user engagement, all while avoiding the
          pitfalls of traditional advertising. Join DigitPop and revolutionize
          your digital content experience.
        </p>

        <!-- Call to action for Publishers -->
        <a href="https://publisher.digitpop.com" class="dpop-publisher-btn"
          >Go to Publisher Tools</a
        >
        <p>
          For consumers, DigitPop represents freedom from the usual subscription
          fees and ad interruptions. By interacting with content on the
          platform, consumers earn blockchain-based tokens, which they can use
          to unlock exclusive content, ranging from articles and videos to
          special in-game items and services. This system empowers consumers to
          choose how they engage with digital content, turning their attention
          and engagement directly into currency for accessing high-quality
          content.
        </p>
        <button class="dpop-got-it-btn" (click)="closeDialog()">Got it</button>

        <!-- Form and other interaction elements -->
        <form (ngSubmit)="addToWaitingList()" class="waiting-list-form">
          <input
            type="email"
            [(ngModel)]="email"
            name="email"
            placeholder="Enter your email"
            required
          />
          <button type="submit" class="dpop-waiting-list-btn">
            Join Our Waiting List
          </button>
        </form>
        <div class="dpop-sign-in-prompt">
          <p>Already a member?</p>
          <button class="dpop-sign-in-btn" (click)="openLoginDialog()">
            Sign In
          </button>
        </div>
      </div>
    </div>
    <button class="dpop-got-it-btn" (click)="closeDialog()">Got it</button>
  </div>
</section>
