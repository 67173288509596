<div class="container">
  <div class="quiz-instructions">
    <h2>Congratulations!</h2>
    <p>You've earned {{earnedPoints}} points!</p>
    <p>What would you like to do next?</p>
  </div>
  <div class="options">
    <button mat-button (click)="playNextVideo()" class="answers--answer">Play Next Video</button>
    <button mat-button (click)="browseProducts()" class="answers--answer">Browse Products</button>
    <button mat-button (click)="closeModal()" class="answers--answer">Close</button>
  </div>
</div>

