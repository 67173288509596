<div class="signup-modal" mat-dialog-content>
  <button [hidden]="hideCloseButton" [mat-dialog-close] cdkFocusInitial
          class="mat-dialog-close">&#10006;
  </button>

  <div *ngIf="errorMessage" class="error-message" [ngClass]="{ 'show': errorMessage }">
    <p>{{errorMessage}}</p>
  </div>

  <form (ngSubmit)="submit()" [formGroup]="signUpForm" class="sign-up-form">
    <div class="form-group email">
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input
          [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
          class="form-control"
          formControlName="email"
          matInput
          type="text"
        />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">Email is required</div>
        </div>
      </mat-form-field>
      <mat-icon aria-hidden="false" aria-label="Email" matPrefix>email</mat-icon>
    </div>

    <div class="form-group password">
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input
          class="form-control"
          formControlName="password"
          id="password"
          matInput
          type="password"
        />
        <div *ngIf="f.password.touched && f.password.invalid" class="alert alert-danger">
          <div *ngIf="f.password.errors.required">Password is required.</div>
        </div>
      </mat-form-field>
      <mat-icon aria-hidden="false" aria-label="Lock" matPrefix>lock</mat-icon>
    </div>

    <div class="form-group passwordConfirmation">
      <mat-form-field>
        <mat-label for="confirm_password">Confirm Password</mat-label>
        <input
          formControlName="confirm_password"
          id="confirm_password"
          matInput
          type="password"
        />
      </mat-form-field>
      <mat-icon aria-hidden="false" aria-label="Lock" matPrefix>lock</mat-icon>
    </div>

    <div class="form-group" style="width: 100%">
      <button class="submitButton mat-button" type="submit">Submit</button>
    </div>
  </form>

</div>
