import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { Cloudinary } from '../helpers/cloudinary';
import { ProjectMedia } from '../models/ProjectMedia';
import { Category } from '../models/category';

// Define the VideoResponse interface
interface VideoResponse {
  videos: ProjectMedia[];
  nextCreatedAt: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class VideosGridService {
  readonly endpoint = `${environment.apiUrl}/api/videos`;
  cachedVideo$: Observable<VideoResponse>; // Change to VideoResponse
  cacheMap: Map<string, Observable<VideoResponse>> = new Map(); // Change to VideoResponse

  constructor(private httpClient: HttpClient) {}

  getVideos = (
    categories: string[],
    limit: number,
    userId: string | null,
    lastCreatedAt: string | null = null
  ): Observable<VideoResponse> => { // Change to Observable<VideoResponse>
    let params = new HttpParams();
    params = categories.length
      ? params.append('categories', categories.join(','))
      : params;
    params = params.append('limit', limit.toString());
    if (userId) {
      params = params.append('userId', userId);
    }
    if (lastCreatedAt) {
      params = params.append('lastCreatedAt', lastCreatedAt);
    }

    // Note: No need to log here each time since it's already being logged inside the pipe
    // Use shareReplay to cache the response
    const cacheKey = params.toString();
    if (!this.cacheMap.has(cacheKey)) {
      const request$ = this.httpClient
        .get<VideoResponse>(this.endpoint, { params }) // Change to VideoResponse
        .pipe(
          tap((response) => {
            console.log(`[getVideos]: Received videos response from the server.`);
          }),
          shareReplay(1),
          catchError((error) => {
            console.error('[getVideos]: Error fetching videos', error);
            throw error;
          })
        );
      this.cacheMap.set(cacheKey, request$);
    }

    return this.cacheMap.get(cacheKey)!; // Use non-null assertion since we know it's been set
  };

  getActiveCategories = (): Observable<Category[]> => {
    const categories = this.httpClient.get(`${this.endpoint}/categories`).pipe(
      map((response: Category[]) => response),
      shareReplay(1)
    );
    return categories;
  };

  private secondsToMMSS = (videos: ProjectMedia[]) => {
    const padWithZero = (time: number) => time.toString().padStart(2, '0');
    const timestamp = (seconds: number) => {
      const minutes = (seconds - (seconds % 60)) / 60;
      const remainingSeconds = Math.round(seconds % 60);
      return `${padWithZero(minutes)}:${padWithZero(remainingSeconds)}`;
    };
    return videos.map((video) => {
      video.media.duration = timestamp(+video.media.duration);
      return video;
    });
  };
}
