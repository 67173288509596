<div class="container">
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <div mat-dialog-content data-simplebar>
    <lib-ngx-image-zoom
      [thumbImage]="url"
      [fullImage]="url"
      [magnification]="1"
      [enableScrollZoom]="true"
      [enableLens]="true"
      [lensWidth]="200">
    </lib-ngx-image-zoom>
  </div>
</div>
