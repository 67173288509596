import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'DigitPop-retry-quiz-modal',
  templateUrl: './retry-quiz-modal.component.html',
  styleUrls: ['./retry-quiz-modal.component.scss'],
})
export class RetryQuizModalComponent {
  constructor(public dialogRef: MatDialogRef<RetryQuizModalComponent>) {}

  close(retry: boolean): void {
    // This method will close the modal and pass back a boolean indicating if the user wants to retry the quiz
    this.dialogRef.close(retry);
  }
}
