<div class="container">
  <form [formGroup]="loginForm">
    <h2>Are you sure you want to logout?</h2>
    <div class="action" mat-dialog-actions>
      <button (click)="logout()" class="btn btn-primary" mat-button>
        <span class="spinner-border spinner-border-sm mr-1"></span>
        Yes
      </button>
      <button style="margin: 8px 0 0;" (click)="close()" [mat-dialog-close] cdkFocusInitial mat-button>
        No
      </button>
    </div>
  </form>
</div>
