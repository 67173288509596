<section class="visitor-popup">
  <h1 class="visitor-popup-heading">
    <span class="visitor-popup-action">{{ templateAction }}</span><br>
    <span class="visitor-popup-reason">{{ templateReason }}</span><br>
    <span class="visitor-popup-points">{{ templateBenefit }}</span>
  </h1>
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="Sign Up">
      <digit-pop-signup
        [campaignId]="campaignId"
        [fromQuiz]="source && source === 'quiz'"
        [fromPlans]="source && source === 'plans'"
        [fromSubscribe]="source && source === 'subscribe'"
        [hideCloseButton]="true"
        [projectId]="projectId"
        [cid]="cid"
        [sid]="sid">
      </digit-pop-signup>
    </mat-tab>
    <mat-tab label="Already A Member">
      <digit-pop-login
        [campaignId]="campaignId"
        [fromQuiz]="source && source === 'quiz'"
        [fromPlans]="source && source === 'plans'"
        [fromSubscribe]="source && source === 'subscribe'"
        [hideCloseButton]="true"
        [projectId]="projectId"
        [cid]="cid"
        [sid]="sid">
      </digit-pop-login>
    </mat-tab>
  </mat-tab-group>
</section>
