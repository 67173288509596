import { Component, OnInit, Inject } from '@angular/core';
import { Campaign } from '../../shared/models/campaign';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { EngagementService } from '../../shared/services/engagement.service';
import { CampaignService } from '../../shared/services/campaign.service';
import { RetryQuizModalComponent } from '../retry-quiz-modal/retry-quiz-modal.component';
import { timer } from 'rxjs';
import { PostQuizSuccessModalComponent } from '../post-quiz-success-modal/post-quiz-success-modal.component';
import { PotentialCreditsModalComponent } from '../potential-credits-modal/potential-credits-modal.component';

@Component({
  selector: 'digit-pop-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit {
  campaign: Campaign;
  answers: any[];
  scoreBubbleIsOpen: boolean;

  constructor(
    private campaignService: CampaignService,
    private engagementService: EngagementService,
    public dialog: MatDialog, // Change here to MatDialog
    private dialogRef: MatDialogRef<QuizComponent>, // Keep dialogRef for closing the current dialog
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.scoreBubbleIsOpen = false;
  }

  ngOnInit(): void {
    console.log(
      'QuizComponent initialized with campaignId:',
      this.data.campaignId,
      'and isUser:',
      this.data.isUser
    );
    if (this.data && !this.data.isUser && this.data.campaignId) {
      console.log('Non-user mode, fetching campaign.');
      this.getCampaign(this.data.campaignId);
    }

    if (this.data && this.data.isUser && this.data.campaignId) {
      console.log('User mode, fetching campaign.');
      this.getCampaign(this.data.campaignId);
    }
  }

  getCampaign(campaignId: string) {
    console.log('Fetching campaign for ID:', campaignId);
    if (campaignId) {
      this.campaignService.getCampaign(campaignId).subscribe(
        (res) => {
          console.log('Campaign retrieved successfully:', res);
          this.campaign = res as Campaign;
          this.buildAnswerArray();
        },
        (err) => {
          console.error('Error retrieving campaign:', err);
        }
      );
    }
  }

  buildAnswerArray() {
    console.log('Building answer array for campaign:', this.campaign);
    const answerBuffer = [];
    answerBuffer.push(this.campaign.verificationWrongAnswer1);
    answerBuffer.push(this.campaign.verificationWrongAnswer2);
    answerBuffer.push(this.campaign.verificationWrongAnswer3);
    answerBuffer.push(this.campaign.verificationWrongAnswer4);
    answerBuffer.push(this.campaign.verificationAnswer);
    this.answers = this.shuffle(answerBuffer);
    console.log('Shuffled answers:', this.answers);
  }

  shuffle(array: any[]): any[] {
    console.log('Shuffling array:', array);
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    console.log('Shuffled array:', array);
    return array;
  }

  onAnswer(answer: string) {
    this.engagementService
      .verificationAnswer(
        answer,
        this.data.engagementId,
        this.data.campaignId,
        this.data.isUser,
        this.data.uuid
      )
      .subscribe({
        next: (response: any) => {
          console.log('Quiz answer response:', response);

          // Toggle the score bubble regardless of the quiz outcome
          this.scoreBubbleToggle();

          if (response.correct) {
            console.log('Answer submitted successfully and it is correct.');

            // If the user is signed in, handle the credits earned
            if (this.data.isUser && response.engagement) {
              this.launchSuccessModal(response.engagement.creditsEarned);
              this.dialogRef.close({
                quizPassed: true,
                creditsEarned: response.engagement.creditsEarned,
              });
            } else if (
              !this.data.isUser &&
              response.potentialCredits !== undefined
            ) {
              // For non-users, you might want to inform them of the potential credits they could earn
              this.launchPotentialCreditsModal(response.potentialCredits);
              this.dialogRef.close({
                quizPassed: true,
                potentialCredits: response.potentialCredits,
              });
            }
          } else {
            console.log('Answer submitted successfully but it is incorrect.');
            // Instead of directly closing the quiz dialog, launch the retry component
            this.launchRetryComponent();
          }
        },
        error: (error: any) => {
          console.error('Error submitting answer:', error);
          // Handle any errors, such as showing a message to the user
        },
      });
  }


  launchPotentialCreditsModal(potentialCredits: number): void {
    this.dialog.open(PotentialCreditsModalComponent, {
      width: '500px', // You can adjust this width as needed
      data: { potentialCredits }, // Pass the number of potential credits to the modal
    });
  }

  private launchRetryComponent() {
    const retryDialogRef = this.dialog.open(RetryQuizModalComponent, {
      width: '80%', // Adjust as necessary
      height: 'auto', // Adjust as necessary
      data: {
        // Include any data you need for the retry dialog
      },
    });

    retryDialogRef.afterClosed().subscribe((retry: boolean) => {
      if (retry) {
        console.log('User chose to retry the quiz.');
        // Optionally, reset quiz state here if needed
        this.resetQuiz();
      } else {
        console.log('User chose not to retry the quiz.');
        this.dialogRef.close({ quizPassed: false }); // Close the original quiz dialog
      }
    });
  }

  scoreBubbleToggle = () => {
    this.scoreBubbleIsOpen = true;

    if (this.scoreBubbleIsOpen) {
      const scoreBubbleTimer = timer(2000);
      scoreBubbleTimer.subscribe((x: any) => {
        this.scoreBubbleIsOpen = false;
      });
    }
  };

  launchSuccessModal(earnedPoints: any) {
    const successDialogRef = this.dialog.open(PostQuizSuccessModalComponent, {
      width: '80%', // Adjust as necessary
      height: 'auto', // Adjust as necessary
      data: { earnedPoints },
    });

    successDialogRef.afterClosed().subscribe((result) => {
      // Handle user's choice after closing the success modal
      if (result.playNextVideo) {
        // Function to play the next video
        //this.playNextVideo();
      } else if (result.browseProducts) {
        // Logic for browsing products
        // this.onShowProduct();
      } // Add conditions as needed based on the options you provide
    });
  }

  private resetQuiz() {
    // Reset quiz logic here, if necessary
    // For example, shuffle the answers again or reset selected answer
  }
}
