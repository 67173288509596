<div class="container bgimg">
  <iframe
    class="videocontainer"
    src="https://www.youtube.com/embed/DWnw_pA6BeE"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>

  <div class="signupgrid">
    <div class="signupgridcell1">
      <button class="signup" (click)="openLoginDialog()" mat-raised-button color color="primary">
        Login
      </button>
    </div>
    <div class="signupgridcell2">
      <button class="signup2" (click)="openDialog()" mat-raised-button color color="primary">
        Create Account
      </button>
    </div>
    <div class="signupgridcell3">
      <button class="signup3" (click)="openDialog()" mat-raised-button color color="primary">
        Create Account
      </button>
    </div>
  </div>

  <div class="rewards">
    <h1>Reward Gallery</h1>
    <div id="body_section_tiles_rwd">
      <div class="tile_main">
        <div class="tile_item">
          <svg class="tile_imge" viewBox="0 0 400 225">
            <use xlink:href="assets/img/app_assets.svg#provider_netflix"></use>
          </svg>
        </div>

        <div class="tile_item">
          <svg class="tile_imge" viewBox="0 0 400 225">
            <use
              xlink:href="assets/img/app_assets.svg#provider_youtubetv"
            ></use>
          </svg>
        </div>

        <div class="tile_item">
          <svg class="tile_imge" viewBox="0 0 400 225">
            <use xlink:href="assets/img/app_assets.svg#provider_hulu"></use>
          </svg>
        </div>

        <div class="tile_item">
          <svg class="tile_imge" viewBox="0 0 400 225">
            <use xlink:href="assets/img/app_assets.svg#provider_amazon"></use>
          </svg>
        </div>

        <div class="tile_item">
          <svg class="tile_imge" viewBox="0 0 400 225">
            <use xlink:href="assets/img/app_assets.svg#provider_spotify"></use>
          </svg>
        </div>

        <div class="tile_item">
          <svg class="tile_imge" viewBox="0 0 400 225">
            <use xlink:href="assets/img/app_assets.svg#provider_apple"></use>
          </svg>
        </div>

        <div class="tile_item">
          <svg class="tile_imge" viewBox="0 0 400 225">
            <use xlink:href="assets/img/app_assets.svg#provider_pandora"></use>
          </svg>
        </div>

        <div class="tile_item">
          <svg class="tile_imge" viewBox="0 0 400 225">
            <use xlink:href="assets/img/app_assets.svg#provider_tidal"></use>
          </svg>
        </div>

        <div class="tile_item">
          <svg class="tile_imge" viewBox="0 0 400 225">
            <use xlink:href="assets/img/app_assets.svg#provider_wsj"></use>
          </svg>
        </div>

        <div class="tile_item">
          <svg class="tile_imge" viewBox="0 0 400 225">
            <use xlink:href="assets/img/app_assets.svg#provider_nytimes"></use>
          </svg>
        </div>

        <div class="tile_item">
          <svg class="tile_imge" viewBox="0 0 400 225">
            <use xlink:href="assets/img/app_assets.svg#provider_usatoday"></use>
          </svg>
        </div>

        <div class="tile_item">
          <svg class="tile_imge" viewBox="0 0 400 225">
            <use xlink:href="assets/img/app_assets.svg#provider_latimes"></use>
          </svg>
        </div>

        <div class="tile_item">
          <svg class="tile_imge" viewBox="0 0 400 225">
            <use xlink:href="assets/img/app_assets.svg#provider_nba"></use>
          </svg>
        </div>

        <div class="tile_item">
          <svg class="tile_imge" viewBox="0 0 400 225">
            <use xlink:href="assets/img/app_assets.svg#provider_mlb"></use>
          </svg>
        </div>

        <div class="tile_item">
          <svg class="tile_imge" viewBox="0 0 400 225">
            <use xlink:href="assets/img/app_assets.svg#provider_gamefly"></use>
          </svg>
        </div>

        <div class="tile_item">
          <svg class="tile_imge" viewBox="0 0 400 225">
            <use xlink:href="assets/img/app_assets.svg#provider_sling"></use>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <!-- Call to action -->
  <!-- <div class="header">
    <div class="header_copy">
      <div>
        <span class="title">Happy Subscriptions!</span>
        <p>
          Want access to popular services like Netflix and Spotify but don't
          want to pay every month for them? Play our Gamified Ads and get
          rewarded subscriptions to popular music, video, games, news and more.
          Sign up today.
        </p>
      </div>
    </div>
  </div> -->

  <!-- Body -->
  <!-- <div>
    <div>
      <h1>How it works</h1>
      <div>
        <div>
          <div id="video_container">
            <iframe
              src="https://www.youtube.com/embed/DWnw_pA6BeE"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="body_column">
          <p>
            Online subscriptions for Music, Video, News, and Gaming services can
            be expensive. We email electronic gift cards for online subscription
            as a reward for completing our gamified ads.
          </p>
          <p>
            Each gamified ad is a 90-second video with a game at the end of the
            ad that has to be completed in order to receive points for the ad Do
            it on your own time, watch ads that are relevant to you.
          </p>
          <p>
            Netflix, for example, may take 1100 points per month to earn. Each
            completed gamified ad may be 100 points. So it would take 10
            completed ads to earn a Netflix electronic gift card delivered by
            email. Watch more ads and earn more electronic gift cards for
            services you select
          </p>
        </div>
      </div>
    </div>
  </div> -->
</div>
