<div class="container bgimg">
  <form class="form" [formGroup]="form" (ngSubmit)="submit()">
    <span class="header"> Please wait while we create your account. </span>

    <!-- <div class="form-group password">
      <label class="labelStyle" for="password">Password</label>
      <input
        matInput
        formControlName="password"
        id="password"
        type="password"
      />
      <div
        *ngIf="f.password.touched && f.password.invalid"
        class="alert alert-danger"
      >
        <div *ngIf="f.password.errors.required">Password is required.</div>
      </div>
    </div>

    <div class="form-group passwordConfirmation">
      <label class="labelStyle" for="confirm_password">Confirm Password</label>
      <input
        matInput
        formControlName="confirm_password"
        id="confirm_password"
        type="password"
      />
    </div>

    <button
      class="submitButton mat-raised-button"
      type="button"
      [disabled]="!form.valid"
      (click)="submit()"
    >
      Submit
    </button>

    <div
      *ngIf="f.confirm_password.touched && f.confirm_password.invalid"
      class="alert alert-danger warnings"
    >
      <div *ngIf="f.confirm_password.errors.required">
        Password is required.
      </div>
      <div *ngIf="f.confirm_password.errors.confirmedValidator">
        Password and Confirm Password must be match.
      </div>
    </div>  -->
  </form>
  <!-- <button
    class="submitButton mat-raised-button"
    type="button"
    [disabled]="!form.valid"
    (click)="submit()"
  >
    Submit
  </button> -->
</div>
