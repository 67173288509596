<div
  *ngIf="videosLoaded"
  [ngClass]="{ 'justify-start': categoryVideosCount < 6 }"
  class="videos-grid"
  cdkScrollable
>
  <div
    *ngFor="let video of videos; let i = index"
    [class]="video.watched && loggedIn ? 'dim' : ''"
    class="video-element"
  >
    <a
      (click)="
        openPlayer(video._id, video.campaignId, video.categoryId, $event, i)
      "
      (mouseenter)="previewVideo($event)"
      class="thumbnail"
      href="#"
    >
      <div class="preview-backdrop"></div>

      <!-- Video hidden by default, shown on hover -->
      <video poster="{{ video.thumbnail?.secure_url }}" muted loop>
        <source src="{{ video.media.secure_url }}" type="video/mp4" />
      </video>

      <!-- Image visible by default, hidden when video is visible -->
      <img alt="video title" src="{{ video.thumbnail?.secure_url }}" />

      <!-- Other elements like the shoppable-video indicator can remain unchanged -->
      <img
        [hidden]="video.watched || !videoTour"
        alt=""
        class="shoppable-video-indicator"
        src="/assets/img/shoppable-video.svg"
      />
    </a>

    <div class="details">
      <a
        (click)="
          openPlayer(video._id, video.campaignId, video.categoryId, $event)
        "
        attr.aria-labelledby="{{ video.name }}"
        class="title"
        href=""
      >
        {{ video.name }}
      </a>
      <div class="detail-header">
        <span class="duration">{{
          video.media.duration | formatDuration
        }}</span>
        <time>{{ prettyDate(video.createdAt) }}</time>
      </div>
    </div>
  </div>
</div>

