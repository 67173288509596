<section>
  <div class="points">
    <div class="points--total">
      <img alt="" class="points--celebration" src="assets/img/celebration.svg">
      <span
        class="points--tooltip"
        tooltipPosition="above"
      >i</span>
      <span class="points--total--opening">you have</span>
      <h1>
        {{authService.currentUserValue.credits}}
        <span class="points--total--opening--backdrop">{{authService.currentUserValue.credits.toString().padEnd(7, '.000')}}</span>
      </h1>
      <span>Points</span>
    </div>
  </div>
  <div class="rewards">
    <div class="rewards--title">
      <img alt="" src="assets/img/trophy.svg">
      <h1 class="rewards--title--heading">
        <span class="rewards--title--outline">Rewards</span>
        <span class="rewards--title--text">Rewards</span>
      </h1>
      <div class="rewards--title--nav">
        <span (click)="navScrollHorizontally()"
              class="rewards--title--nav--left"></span>
        <span (click)="navScrollHorizontally(true)"
              class="rewards--title--nav--right"></span>
      </div>
    </div>
    <div (wheel)="wheelScrollHorizontally($event)" class="rewards--gallery">
      <div (click)="RedeemReward(reward.name, reward.points)"
           *ngFor="let reward of sortByPoints(rewards)"
           [class]="reward.points > +authService.currentUserValue.credits ? 'rewards--gallery--item--disabled' : ''"
           class="rewards--gallery--item">
        <span class="rewards--gallery--item--points">{{reward.points}}
          Points</span>
        <svg class="rewards--gallery--item--logo" fill="lightgrey"
             viewBox="0 0 400 225">
          <use
            [attr.xlink:href]="'assets/img/app_assets.svg#provider_'+reward.name"></use>
        </svg>
      </div>
    </div>
  </div>
</section>
