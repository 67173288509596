import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WaitingListService } from '../../shared/services/waitinglist.service';

@Component({
  selector: 'app-potential-credits-modal',
  templateUrl: './potential-credits-modal.component.html',
  styleUrls: ['./potential-credits-modal.component.scss'],
})
export class PotentialCreditsModalComponent {
  email: string = '';
  message: string = '';
  isError: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { potentialCredits: number },
    private dialogRef: MatDialogRef<PotentialCreditsModalComponent>, // Inject MatDialogRef
    private waitingListService: WaitingListService // Inject the WaitingListService
  ) {}

  closeModal(): void {
    this.dialogRef.close(); // Use the dialogRef to close the modal
  }

  addToWaitingList(): void {
    console.log('In addToWaitingList()');
    if (this.email) {
      this.waitingListService.addToWaitingList(this.email).subscribe(
        (response) => {
          // Handle success
          this.isError = false;
          this.message = 'Successfully added to the waiting list!';
          this.email = ''; // Reset the email field if needed
        },
        (error) => {
          // Handle error
          this.isError = true;
          const serverErrorMessage =
            error.error.message ||
            'Could not add to the waiting list. Please try again.';
          this.message = `Error: ${serverErrorMessage}`;
        }
      );
    }
  }
}
