import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-post-quiz-success-modal',
  templateUrl: './post-quiz-success-modal.component.html',
  styleUrls: ['./post-quiz-success-modal.component.scss']
})
export class PostQuizSuccessModalComponent {

  public earnedPoints: number;

  constructor(
    public dialogRef: MatDialogRef<PostQuizSuccessModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.earnedPoints = data.points;
  }

  playNextVideo(): void {
    this.dialogRef.close({ action: 'playNext' });
  }

  retryQuiz(): void {
    this.dialogRef.close({ action: 'retry' });
  }

  browseProducts(): void {
    this.dialogRef.close({ action: 'browseProducts' });
  }

  closeModal(): void {
    this.dialogRef.close({ action: 'close' });
  }
}
