import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { WaitingListService } from '../shared/services/waitinglist.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-intro-popup',
  templateUrl: './intro-popup.component.html',
  styleUrls: ['./intro-popup.component.scss'],
})
export class IntroPopupComponent implements OnInit {
  email: string = '';
  message: string = '';
  isError: boolean = false;
  currentVideo = {
    src: '../../assets/video/video_10.mp4',
    poster: '../../assets/video/video_10.jpg',
  };

  constructor(
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<IntroPopupComponent>,
    private dialog: MatDialog,
    private waitingListService: WaitingListService
  ) {}

  ngOnInit(): void {
    this.currentVideo.src = '../../assets/video/video_12.mp4';
  }

  changeVideo(type: string): void {
    if (type === 'publisher') {
      this.currentVideo.src = '../../assets/video/video_12.mp4';
      this.currentVideo.poster = '../../assets/video/video_12.jpg';
    } else {
      this.currentVideo.src = '../../assets/video/video_10.mp4';
      this.currentVideo.poster = '../../assets/video/video_10.jpg';
    }
    this.cdr.detectChanges(); // Manually trigger change detection
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  openLoginDialog(): void {
    this.closeDialog();
    this.dialog
      .open(LoginComponent, { panelClass: 'dpop-modal' })
      .afterClosed()
      .subscribe(() => {
        console.log('The login dialog was closed');
      });
  }

  addToWaitingList(): void {
    if (this.email) {
      this.waitingListService.addToWaitingList(this.email).subscribe(
        (response) => {
          this.isError = false;
          this.message = 'Successfully added to the waiting list!';
          this.email = ''; // Reset the input field
        },
        (error) => {
          this.isError = true;
          const serverErrorMessage =
            error.error.message ||
            'Could not add to the waiting list. Please try again.';
          this.message = `Error: ${serverErrorMessage}`;
        }
      );
    }
  }
}
