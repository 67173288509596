<div class="container">
  <div class="sidebar">
    <h1 class="sidebar__title">
      A <span class="sidebar__title-emphasis">Different</span><br> online ad<br> network<br>
      <span class="sidebar__subtitle">increase your <span class="sidebar__subtitle-emphasis">roi</span></span>
    </h1>
  </div>
  <div class="signup">
    <div class="progress__bar" *ngIf="isSubmitting">
      <mat-spinner class="spinner" [diameter]="15"></mat-spinner>
      <p class="progress__bar-message">
        <span class="progress__bar-action">Hold on,</span>
        {{submissionMessage}}
      </p>
    </div>
    <h1 class="signup__title">
      <span class="signup__title-opacity--25">Join </span>
      <span class="signup__title-opacity--50">Digitpop </span><br>
      <span class="signup__title-opacity--75">As </span>
      <span class="signup__title-color--accent">Business</span>
    </h1>
    <div class="signup__content">
      <ul class="signup__steps">
        <li class="signup__step" [ngClass]="{'signup__step--active': currentStep === 1}">
          <span class="signup__step-text" [hidden]="currentStep > 1">01</span>
          <span class="signup__step-text" *ngIf="currentStep > 1">
            <img src="assets/img/icons/checkIcon.svg" alt="checkmark icon">
          </span>
        </li>
        <li class="signup__line"></li>
        <li class="signup__step" [ngClass]="{'signup__step--active': currentStep === 2}">
          <span class="signup__step-text" [hidden]="currentStep > 2">02</span>
          <span class="signup__step-text" *ngIf="currentStep > 2">
            <img src="assets/img/icons/checkIcon.svg" alt="checkmark icon">
          </span>
        </li>
        <li class="signup__line"></li>
        <li class="signup__step" [ngClass]="{'signup__step--active': currentStep === 3}">
          <span class="signup__step-text" [hidden]="currentStep > 3">03</span>
          <span class="signup__step-text" *ngIf="currentStep > 3">
            <img src="assets/img/icons/checkIcon.svg" alt="checkmark icon">
          </span>
        </li>
        <li class="signup__line" *ngIf="planName !== 'free'"></li>
        <li class="signup__step" *ngIf="planName !== 'free'" [ngClass]="{'signup__step--active': currentStep === 4}">
          <span class="signup__step-text" [hidden]="currentStep > 4">04</span>
          <span class="signup__step-text" *ngIf="currentStep > 4">
            <img src="assets/img/icons/checkIcon.svg" alt="checkmark icon">
          </span>
        </li>
        <li class="signup__line" *ngIf="planName !== 'free'"></li>
        <li class="signup__step" *ngIf="planName !== 'free'" [ngClass]="{'signup__step--active': currentStep === 5}">
          <span class="signup__step-text" [hidden]="currentStep > 5">05</span>
          <span class="signup__step-text" *ngIf="currentStep > 5">
            <img src="assets/img/icons/checkIcon.svg" alt="checkmark icon">
          </span>
        </li>
      </ul>

      <form class="signup__form" [formGroup]="signupForm">

        <div *ngIf="currentStep === 1" class="signup__inputs">
          <div class="signup__form-field">
            <label class="signup__form-label">First Name</label>
            <input type="text" formControlName="firstName" id="firstname" class="signup__form-input" autocomplete="off">
            <div
              *ngIf="signupForm.controls['firstName'].invalid && (signupForm.controls['firstName'].dirty || signupForm.controls['firstName'].touched)">
              <div class="signup__form-error">Please enter your first name.</div>
            </div>
          </div>
          <div class="signup__form-field">
            <label class="signup__form-label">Last Name</label>
            <input type="text" formControlName="lastName" class="signup__form-input" autocomplete="off">
            <div
              *ngIf="signupForm.controls['lastName'].invalid && (signupForm.controls['lastName'].dirty || signupForm.controls['lastName'].touched)">
              <div class="signup__form-error">Please enter your last name.</div>
            </div>
          </div>
          <div class="signup__form-field">
            <label class="signup__form-label">Email</label>
            <input type="email" formControlName="email" id="email" class="signup__form-input" autocomplete="off">
            <div
              *ngIf="signupForm.controls['email'].invalid && (signupForm.controls['email'].dirty || signupForm.controls['email'].touched)">
              <div class="signup__form-error">Please enter your email.</div>
            </div>
          </div>
        </div>

        <div *ngIf="currentStep === 2" class="signup__inputs">
          <div class="signup__form-field">
            <label class="signup__form-label">Password</label>
            <input type="password" formControlName="password" id="password" class="signup__form-input" autocomplete="off">
            <div *ngIf="signupForm.get('password').invalid && signupForm.get('password').touched">
              <div class="signup__form-error">Your password should be at least 8 characters long, contain at 1 capital letter, 1 small letter, 1 number and 1 symbol.</div>
            </div>
          </div>
          <div class="signup__form-field">
            <label class="signup__form-label">Confirm Password</label>
            <input type="password" formControlName="confirmPassword" id="confirmPassword" class="signup__form-input"
              autocomplete="off">
            <div *ngIf="signupForm.get('confirmPassword').errors?.passwordMismatch && signupForm.get('confirmPassword').touched">
              <div class="signup__form-error">Passwords do not match.</div>
            </div>
          </div>
          <div class="signup__terms">
            <div class="signup__terms-checkbox" (click)="toggleTermsCheckbox()">
              <div class="signup__terms-checkbox-input" [class.checked]="termsCheckboxChecked" formControlName="agreeToTerms" id="agreeToTerms">
                  <span class="signup__terms-checkbox-icon">
                    <span *ngIf="signupForm.controls['agreeToTerms'].value" class="checkmark">&#10004;</span>
                  </span>
              </div>
              <label class="signup__terms-checkbox-label" for="agreeToTerms">
                <span class="signup__terms-checkbox-text">
                  I agree to<a class="signup__terms-checkbox-text-bold" (click)="redirectToTerms()"> Terms and Conditions</a>
                </span>
              </label>
            </div>
            <div class="signup__terms-error"
              *ngIf="signupForm.controls['agreeToTerms'].invalid && (signupForm.controls['agreeToTerms'].dirty || signupForm.controls['agreeToTerms'].touched)">
              <div class="signup__terms-error-text">Please agree to the terms and conditions.</div>
            </div>
          </div>

        </div>

        <div *ngIf="currentStep === 3" class="signup__inputs signup__step--plan">
          <h2 class="signup__step-title" *ngIf="!cid && !sid">Choose your plan</h2>
          <div class="signup__plan-cards">
            <div *ngFor="let plan of plans" class="signup__plan-card signup__plan-card--{{ plan.name }}" [ngClass]="{'signup__plan-card--active': (!planName && plan.name === 'free') || (planName && plan.name === planName)}" (click)="setPlanName(plan.name, plan.cycles[plan.cycles.length - 1].cycleId)">
              <div class="signup__plan-title">
                <div>
                  <h3 class="signup__plan-name">{{ plan.displayName }}</h3>
                  <img src="assets/img/icons/{{ plan.name }}PlanIcon.svg" alt="{{plan.name}} icon" class="signup__plan-icon">
                </div>
                <h4 class="signup__plan-price">
                  <span class="signup__plan-currency">&#36; </span>{{ removeCurrencyChar(plan.cycles[0].pricingModel.priceFormatted) }}<span
                    class="signup__plan-period">/month</span>
                </h4>
              </div>
              <p class="signup__plan-description">
                {{ plan.description }}
              </p>
            </div>
          </div>
        </div>

        <div *ngIf="currentStep === 4 && (planName !== 'free')" class="signup__inputs" [formGroup]="addressForm">
          <div class="signup__form-two-fields">
            <div class="signup__form-field signup__form-field-half">
              <label class="signup__form-label">Address Line 1</label>
              <input type="text" formControlName="addressLine1" id="addressLine1" class="signup__form-input" autocomplete="off">
              <div
                *ngIf="addressForm.controls['addressLine1'].invalid && (addressForm.controls['addressLine1'].dirty || addressForm.controls['addressLine1'].touched)">
                <div class="signup__form-error">Please enter your address Line 1.</div>
              </div>
            </div>

            <div class="signup__form-field signup__form-field-half">
              <label class="signup__form-label">Address Line 2</label>
              <input type="text" formControlName="addressLine2" class="signup__form-input" autocomplete="off">
              <div
                *ngIf="addressForm.controls['addressLine2'].invalid && (addressForm.controls['addressLine2'].dirty || addressForm.controls['addressLine2'].touched)">
                <div class="signup__form-error">Please enter your address Line 2.</div>
              </div>
            </div>
          </div>

          <div class="signup__form-two-fields">
            <div class="signup__form-field signup__form-field-half">
              <label class="signup__form-label">City</label>
              <input type="text" formControlName="city" class="signup__form-input" autocomplete="off">
              <div
                *ngIf="addressForm.controls['city'].invalid && (addressForm.controls['city'].dirty || addressForm.controls['city'].touched)">
                <div class="signup__form-error">Please enter your city.</div>
              </div>
            </div>

            <div class="signup__form-field signup__form-field-half">
              <label class="signup__form-label">State / Province / County</label>
              <input type="text" formControlName="state" class="signup__form-input" autocomplete="off">
              <div
                *ngIf="addressForm.controls['state'].invalid && (addressForm.controls['state'].dirty || addressForm.controls['state'].touched)">
                <div class="signup__form-error">Please enter your state / province / county.</div>
              </div>
            </div>
          </div>

          <div class="signup__form-field">
            <label class="signup__form-label">ZIP / Postal Code</label>
            <input type="text" formControlName="postalCode" class="signup__form-input" autocomplete="off">
            <div
              *ngIf="addressForm.controls['postalCode'].invalid && (addressForm.controls['postalCode'].dirty || addressForm.controls['postalCode'].touched)">
              <div class="signup__form-error">Please enter your ZIP / postal code.</div>
            </div>
          </div>

          <div class="signup__form-field">
            <label class="signup__form-label">Country</label>
            <select formControlName="country" class="signup__form-input" autocomplete="off">
              <option value="">Select Country</option>
              <option *ngFor="let country of countries" [value]="country.code">{{ country.name }}</option>
            </select>
            <div
              *ngIf="addressForm.controls['country'].invalid && (addressForm.controls['country'].dirty || addressForm.controls['country'].touched)">
              <div class="signup__form-error">Please select your country.</div>
            </div>
          </div>
        </div>

        <div *ngIf="currentStep === 5 " id="payment-form" class="signup__inputs signup__inputs-payment-form" [formGroup]="cardDetailsForm">
          <input type="hidden" name="payment_method_token" id="payment_method_token">

          <div class="signup__form-field">
            <label class="signup__form-label">Credit Card Number</label>
            <div id="billsby-number" class="signup__form-input"></div>
          </div>

          <div class="signup__form-field">
            <label class="signup__form-label">CVV</label>
            <div id="billsby-cvv" class="signup__form-input"></div>
          </div>

          <div class="signup__form-field">
            <label class="signup__form-label">Expiration Date</label>
            <div class="signup__form-expiry">
              <input type="text" id="month" name="month" maxlength="2" class="signup__form-input" style="flex-grow: 1;"
                placeholder="Month" formControlName="expiryMonth">
              <input type="text" id="year" name="year" maxlength="4" class="signup__form-input" style="flex-grow: 1;"
                placeholder="Year" formControlName="expiryYear">
            </div>
          </div>
        </div>

      </form>

      <button class="btn signup__btn" [disabled]="isNextButtonDisabled" [class.disabled]="isNextButtonDisabled" (click)="handleButtonClick($event)" *ngIf="currentStep <= 5">
        <span *ngIf="(currentStep !== 3 && currentStep !== 5) || (currentStep === 3 && planName !== 'free')">Next <img src="assets/img/icons/nextArrow.svg"
            alt=""></span>
        <span *ngIf="currentStep === 5 || (planName === 'free' && currentStep === 3)">Create Account</span>
      </button>
    </div>
  </div>
</div>