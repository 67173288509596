export const environment = {
  production: true,
  apiUrl: 'https://digitpop.azurewebsites.net', // Update this line with your server's URL
  playerUrl: 'https://player.digitpop.com',
  billsbyUrl: 'https://public.billsby.com/api/v1/rest/core/Digitpop',
  billsbyKey: 'digitpop_f1efba4792104a3ab66c1cb59f43993a',
  cloudinaryUrl: 'https://api.cloudinary.com/v1_1/',
  CLOUDINARY_CLOUD_NAME: 'boggssystems',
  CLOUDINARY_UPLOAD_PRESET: 'yspatu75',
  websocketURL: 'wss://digitpop.azurewebsites.net' // Update to use wss if needed
};

