<digit-pop-user-rewards></digit-pop-user-rewards>
<section class="dashboard--redemption">
  <div class="container">

    <ul class="dashboard--tabs">
      <li class="disabled">
        <img alt="" src="assets/img/dashboard-home.svg">
        <a href="#">Overview</a>
      </li>
      <li class="active">
        <img alt="" src="assets/img/dashboard-redemption.svg">
        <a class="active" href="#">Redemption</a>
      </li>
      <li class="disabled">
        <img alt="" src="assets/img/dashboard-key.svg">
        <a href="#">Account</a>
      </li>
      <li class="disabled">
        <img alt="" src="assets/img/dashboard-setting.svg">
        <a href="#">Settings</a>
      </li>
    </ul>

    <div class="dashboard--content">
      <div class="dashboard--content--analysis">
        <div class="analysis-header">
          <span class="border--left--diamond"></span>
          <h2 class="dashboard--content--heading">Quick Stats</h2>
        </div>

        <div class="stats-grid">
          <div *ngFor="let stat of stats" class="dashboard--content--analysis--card {{stat.class}}">
            <img [attr.src]="'assets/img/'+stat.icon" alt="cup icon">
            <h2>{{stat.value}}</h2>
            <p><span>{{stat.span}}</span>{{stat.description}}</p>
          </div>
        </div>
      </div>

      <div class="dashboard--redemption">
        <div class="redemption-header">
          <span class="border--left--diamond"></span>
          <h2 class="dashboard--content--heading">History</h2>
        </div>

        <div class="redemptions-grid">
          <div *ngFor="let redemption of myRedemptions; let i = index" class="dashboard--redemption--card">
            <div class="card--content--cups-image">
              <span class="card--content--points">- {{redemption.points}}</span>
              <img alt="illustration of cup" src="assets/img/trophy.svg">
            </div>
            <svg class="card--content--logo" viewBox="70 100">
              <use [attr.xlink:href]="'assets/img/app_assets.svg#provider_'+redemption.reward"></use>
            </svg>
            <span class="card--content-date">{{prettyDate(redemption.createdAt)}}</span>
            <span (click)="onCopyToClipboard('giftCardCode')" class="dashboard--redemption--card--copy"></span>
            <span class="dashboard--redemption--card--number">{{redemptionsCount - i}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
