import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'DigitPop-project-wizard-youtube-popup',
    templateUrl: './youtube-popup.component.html',
    styleUrls: ['./youtube-popup.component.scss']
})
export class ProjectWizardYoutubePopup implements OnInit {
    constructor() { }
    ngOnInit(): void { }
}
