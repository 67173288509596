<div class="login-modal" mat-dialog-content>
  <button mat-dialog-close class="mat-dialog-close" aria-label="Close dialog">
    &#10006;
  </button>
  <button [hidden]="hideCloseButton" mat-dialog-close class="mat-dialog-close" aria-label="Close">&#10006;</button>
  <form (ngSubmit)="onSubmit()" [formGroup]="loginForm" class="login-form">
    <!-- Toggle between consumer and business -->
    <div class="toggle-role">
      <mat-radio-group aria-label="Select an option" formControlName="role" class="mat-radio-group">
        <mat-radio-button class="check" value="1">As a consumer</mat-radio-button>
        <mat-radio-button class="check" value="2">As a business</mat-radio-button>
      </mat-radio-group>
    </div>
    <!-- Email input -->
    <div class="form-group">
      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email" class="form-control">
        <mat-icon matPrefix>email</mat-icon>
        <mat-error *ngIf="f.email.errors?.required">Email is required</mat-error>
      </mat-form-field>
    </div>
    <!-- Password input -->
    <div class="form-group">
      <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password" class="form-control">
        <mat-icon matPrefix>lock</mat-icon>
        <mat-error *ngIf="f.password.errors?.required">Password is required</mat-error>
      </mat-form-field>
    </div>
    <!-- Keep me signed in -->
    <div class="form-group">
      <mat-checkbox formControlName="keepSignedIn" color="primary">Keep me signed in</mat-checkbox>
    </div>
    <!-- Login button -->
    <div class="form-group">
      <button type="submit" class="btn btn-primary" mat-raised-button>Login</button>
    </div>
  </form>
</div>
