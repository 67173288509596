<div class="container">
  <div class="quiz-instructions">
    Answer correctly to earn tokens for unlocking exclusive content.
  </div>
  <div class="question">
    {{ campaign?.verificationQuestion }}
  </div>

  <div class="answers">
    <div *ngFor="let item of answers">
      <button (click)="onAnswer(item)" class="answers--answer" mat-flat-button>
        <span class="answers--answer--text">{{ item }}</span>
      </button>
    </div>
  </div>
</div>

<digit-pop-quiz-animation
  [scoreBubbleIsOpen]="scoreBubbleIsOpen"
></digit-pop-quiz-animation>
